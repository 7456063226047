// 內文動畫
export default {
  methods: {
    handleParagraph() {
      const vm = this;
      vm.handleParagraphPageTwo();
      vm.handleParagraphPageThree();
      vm.handleParagraphPageFive();
      vm.handleParagraphPageSeven();
      vm.handleParagraphPageEight();
      vm.handleParagraphPageNine();
      vm.handleParagraphPageTen();
      vm.handleParagraphPageEleven();
      vm.handleParagraphPageTwelve();
      vm.handleParagraphPageThirteen();
      vm.handleParagraphPageFourteen();
      vm.handleParagraphPageFifteen();
    },
    // 第二頁
    handleParagraphPageTwo() {
      const vm = this;
      const scene = vm.$scrollmagic.scene({
        triggerElement: '#trigger2',
        triggerHook: 0.5,
        duration: 300,
      })
        .setTween('#p-animate2', {
          css: {
            transform: 'translateY(0px)',
            opacity: 1, // the tween durtion can be omitted and defaults to 1
          },
        });
      vm.$scrollmagic.addScene(scene);
    },
    // 第三頁
    handleParagraphPageThree() {
      const vm = this;
      const scene = vm.$scrollmagic.scene({
        triggerElement: '#trigger3',
        triggerHook: 0.5,
        duration: 300,
      })
        .setTween('#p-animate3', {
          css: {
            transform: 'translateY(0px)',
            opacity: 1, // the tween durtion can be omitted and defaults to 1
          },
        });
      vm.$scrollmagic.addScene(scene);
    },
    // 第五頁
    handleParagraphPageFive() {
      const vm = this;
      const scene = vm.$scrollmagic.scene({
        triggerElement: '#triggerr4-2',
        triggerHook: 0.5,
        duration: 300,
        offset: vm.clientMode === 'tablet' ? 500 : 0,
      })
        .setTween('#p-animate5', {
          css: {
            transform: 'translateY(0px)',
            opacity: 1, // the tween durtion can be omitted and defaults to 1
          },
        });
      vm.$scrollmagic.addScene(scene);
    },
    // 第七頁
    handleParagraphPageSeven() {
      const vm = this;
      const scene = vm.$scrollmagic.scene({
        triggerElement: '#trigger7',
        triggerHook: vm.clientMode === 'pc' ? 0.5 : 0.8,
        duration: 300,
      })
        .setTween('#p-animate7', {
          css: {
            transform: 'translateY(0px)',
            opacity: 1, // the tween durtion can be omitted and defaults to 1
          },
        });
      vm.$scrollmagic.addScene(scene);
    },
    // 第八頁
    handleParagraphPageEight() {
      const vm = this;
      const scene = vm.$scrollmagic.scene({
        triggerElement: '#trigger8',
        triggerHook: 0.1,
        duration: 300,
      })
        .setTween('#fader', {
          css: {
            background: 'linear-gradient(to top,rgba(255, 255, 255, 0.9) 0%,rgba(255, 255, 255, 0.8) 5%,rgba(255, 255, 255, 0) 20%,rgba(255, 255, 255, 0) 80%,rgba(255, 255, 255, 0.8) 95%,rgba(255, 255, 255, 0.9) 100%)',
          },
        });
        // .addIndicators({ name: '2 (duration: 300)' });
      vm.$scrollmagic.addScene(scene);
      const scene2 = vm.$scrollmagic.scene({
        triggerElement: '#trigger8',
        triggerHook: 0.8,
        duration: 300,
      })
        .setTween('#fader', {
          css: {
            background: 'linear-gradient(to top,rgba(255, 255, 255, 0.9) 0%,rgba(255, 255, 255, 0.8) 5%,rgba(255, 255, 255, 0) 20%,rgba(0, 0, 0, 0) 80%,rgba(0, 0, 0, 0.8) 95%,rgba(0, 0, 0, 0.9) 100%)',
          },
        });
      vm.$scrollmagic.addScene(scene2);
    },
    // 第九頁
    handleParagraphPageNine() {
      const vm = this;
      // 9-1
      const scene = vm.$scrollmagic.scene({
        triggerElement: '#trigger9',
        triggerHook: 0.6,
        duration: 300,
      })
        .setTween('#p-animate9-1', {
          css: {
            transform: 'translateY(0px)',
            opacity: 1, // the tween durtion can be omitted and defaults to 1
          },
        });
      vm.$scrollmagic.addScene(scene);
      // 9-2
      const scene2 = vm.$scrollmagic.scene({
        triggerElement: '#p-animate9-1',
        triggerHook: 0.6,
        duration: 300,
      })
        .setTween('#p-animate9-2', {
          css: {
            transform: 'translateY(0px)',
            opacity: 1, // the tween durtion can be omitted and defaults to 1
          },
        });
      vm.$scrollmagic.addScene(scene2);
      // 9-3
      const scene3 = vm.$scrollmagic.scene({
        triggerElement: '#p-animate9-2',
        triggerHook: 0.6,
        duration: 300,
      })
        .setTween('#p-animate9-3', {
          css: {
            transform: 'translateY(0px)',
            opacity: 1, // the tween durtion can be omitted and defaults to 1
          },
        });
      vm.$scrollmagic.addScene(scene3);
    },
    // 第十頁
    handleParagraphPageTen() {
      const vm = this;
      // 10-1
      const scene = vm.$scrollmagic.scene({
        triggerElement: '#trigger10',
        triggerHook: 0.6,
        duration: 300,
      })
        .setTween('#p-animate10-1', {
          css: {
            transform: 'translateY(0px)',
            opacity: 1, // the tween durtion can be omitted and defaults to 1
          },
        });
      vm.$scrollmagic.addScene(scene);
      // 10-2
      const scene2 = vm.$scrollmagic.scene({
        triggerElement: '#p-animate10-1',
        triggerHook: 0.6,
        duration: 300,
      })
        .setTween('#p-animate10-2', {
          css: {
            transform: 'translateY(0px)',
            opacity: 1, // the tween durtion can be omitted and defaults to 1
          },
        });
      vm.$scrollmagic.addScene(scene2);
    },
    // 第十一頁
    handleParagraphPageEleven() {
      const vm = this;
      const scene = vm.$scrollmagic.scene({
        triggerElement: '#trigger11',
        triggerHook: 0.7,
        duration: 300,
      })
        .setTween('#p-animate11', {
          css: {
            transform: 'translateY(0px)',
            opacity: 1, // the tween durtion can be omitted and defaults to 1
          },
        });
      vm.$scrollmagic.addScene(scene);
    },
    // 第十二頁
    handleParagraphPageTwelve() {
      const vm = this;
      const scene = vm.$scrollmagic.scene({
        triggerElement: '#trigger12',
        triggerHook: 0.7,
        duration: 300,
      })
        .setTween('#p-animate12', {
          css: {
            transform: 'translateY(0px)',
            opacity: 1, // the tween durtion can be omitted and defaults to 1
          },
        });
      vm.$scrollmagic.addScene(scene);
    },
    // 第十三頁
    handleParagraphPageThirteen() {
      const vm = this;
      // 13-1
      const scene = vm.$scrollmagic.scene({
        triggerElement: '#t-animate13',
        triggerHook: 0.7,
        duration: 300,
      })
        .setTween('#p-animate13-1', {
          css: {
            transform: 'translateY(0px)',
            opacity: 1, // the tween durtion can be omitted and defaults to 1
          },
        });
      vm.$scrollmagic.addScene(scene);
      // 13-2
      const scene2 = vm.$scrollmagic.scene({
        triggerElement: '#p-animate13-1',
        triggerHook: 0.5,
        duration: 300,
      })
        .setTween('#p-animate13-2', {
          css: {
            transform: 'translateY(0px)',
            opacity: 1, // the tween durtion can be omitted and defaults to 1
          },
        });
      vm.$scrollmagic.addScene(scene2);
      // 13-3
      const scene3 = vm.$scrollmagic.scene({
        triggerElement: '#p-animate13-2',
        triggerHook: 0.5,
        duration: 300,
      })
        .setTween('#p-animate13-3', {
          css: {
            transform: 'translateY(0px)',
            opacity: 1, // the tween durtion can be omitted and defaults to 1
          },
        });
      vm.$scrollmagic.addScene(scene3);
    },
    // 第十四頁
    handleParagraphPageFourteen() {
      const vm = this;
      // 14-1
      const scene = vm.$scrollmagic.scene({
        triggerElement: '#trigger14',
        triggerHook: 0.5,
        duration: 300,
      })
        .setTween('#p-animate14-1', {
          css: {
            transform: 'translateY(0px)',
            opacity: 1, // the tween durtion can be omitted and defaults to 1
          },
        });
      vm.$scrollmagic.addScene(scene);
      // 14-2
      const scene2 = vm.$scrollmagic.scene({
        triggerElement: '#p-animate14-1',
        triggerHook: 0.5,
        duration: 300,
      })
        .setTween('#p-animate14-2', {
          css: {
            transform: 'translateY(0px)',
            opacity: 1, // the tween durtion can be omitted and defaults to 1
          },
        });
      vm.$scrollmagic.addScene(scene2);
    },
    // 第十五頁
    handleParagraphPageFifteen() {
      const vm = this;
      const scene = vm.$scrollmagic.scene({
        triggerElement: '#trigger15',
        triggerHook: vm.clientMode !== 'pc' ? 0.8 : 0.4,
        duration: 300,
      })
        .setTween('#fader', {
          css: {
            background: 'linear-gradient(to top,transparent 0%,transparent 5%,transparent 20%,transparent 80%,rgba(255, 255, 255, 0.8) 95%,rgba(255, 255, 255, 0.9) 100%)',
          },
        });
      vm.$scrollmagic.addScene(scene);
      const scene2 = vm.$scrollmagic.scene({
        triggerElement: '#trigger15',
        triggerHook: 0.2,
        duration: 300,
      })
        .setTween('#fader', {
          css: {
            background: 'linear-gradient(to top,transparent 0%,transparent 5%,transparent 20%,transparent 80%,transparent 95%,transparent 100%)',
          },
        });
      vm.$scrollmagic.addScene(scene2);
    },
  },
};
