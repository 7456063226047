<template>
  <div id="app">
    <!-- 第1頁 -->
    <section
      class="theme theme-dark theme-full-vh intro"
      :class="{ 'video-fade-out': fadeVideo }"
    >
      <video
        ref="video"
        muted
        autoplay
        autobuffer
        preload
        playsinline
        src="~@/assets/img/video/virus.mp4"
        @ended="hideVideo"
      ></video>
      <header class="header">
        <h1 class="logo">
          <img :src="require('@/assets/img/gallery/fordays.png')" />
        </h1>
      </header>

      <div class="intro-content layout">
        <h2 class="title mb-1">來自美國政府的暗黑技術</h2>
        <p class="detail">
          美國桑迪亞國家實驗室隸屬國家能源部，桑迪亞國家實驗室致力於維持核武系統的可靠性和準確性，及美國核武計劃產生的有害廢棄物的處理、生物學、數學、材料科學、替代能源、心理學、微機電系統和認知科學在內的各個學科研究。
        </p>
      </div>
    </section>
    <!-- 第2頁 -->
    <section class="theme theme-center theme-dark">
      <div id="trigger2" class="layout layout-divide">
        <!-- backgorund-imgae OR <img /> -->
        <figure
          id="f-animate-1"
          class="layout-item gallery layout-item-gap-r f-animate-1"
        >
          <img :src="require('@/assets/img/gallery/special-tech.png')" />
          <div id="f-animate-1-mask" class="f-animate-1-mask"></div>
        </figure>
        <div class="layout-item typography">
          <h2
            id="t-animate2"
            class="title title-gradient mb-1"
            v-html="
              clientMode !== 'mobile'
                ? '來自台灣國家實驗室的驚異科技'
                : '來自台灣國家實驗室的\n驚異科技'
            "
          ></h2>
          <p id="p-animate2" class="detail">
            台灣工業技術研究院48年來引領著台灣科技,
            1981年當時行政院長孫運璿邀請台積電董事長張忠謀到工研院出任院長一職。
            工研院不但是國際級的應用研究機構，更帶領著台灣各領域產業邁向國際級的舞台,見證著台灣科技的創世成績。
          </p>
        </div>
      </div>
    </section>
    <!-- 第3頁 -->
    <section class="theme theme-center theme-dark diligent">
      <div id="trigger3" class="layout layout-divide reverse">
        <!-- backgorund-imgae OR <img /> -->
        <figure id="f-animate-2" class="layout-item gallery f-animate-2">
          <img :src="require('@/assets/img/gallery/fordays.png')" />
          <div id="f-animate-2-mask" class="f-animate-1-mask"></div>
        </figure>
        <div class="layout-item typography layout-item-gap-r">
          <h2 id="t-animate3" class="title title-gradient mb-1">
            來自 FORDAYS 的用心把關
          </h2>
          <p id="p-animate3" class="detail">
            日商富地滋FORDAYS是日本前三大傳銷公司，在全球有百餘個營業據點,全球會員人數達50萬人
            ,在2020年疫情的環伺下仍創造出年營業額360餘億日元的傲人成績。
          </p>
        </div>
      </div>
    </section>
    <!-- 第4頁 -->
    <section id="trigger4-1" class="theme theme-dark succeeded">
      <!-- backgorund-imgae OR <img /> -->
      <figure class="succeeded-figure">
        <img :src="require('@/assets/img/gallery/succeeded-bg.png')" />
      </figure>
      <div class="typography succeeded-typography">
        <p
          id="t-animate4-1"
          class="title title-origin title-translate text-center mb-1"
        >
          強強聯手 光合鈦 在地森耕 磅礡上市
        </p>
        <p
          id="t-animate4-2"
          class="title title-origin title-translate text-center mb-1"
        >
          經過與台灣國家實驗室的共同努力
        </p>
        <div id="t-animate4-3" class="banner title-translate">我們成功了!</div>
      </div>
    </section>
    <section class="theme theme-dark army">
      <div id="triggerr4-2" class="layout layout-divide">
        <figure
          id="f-animate-4"
          class="layout-item layout-item-gap-r f-animate-4"
        >
          <img :src="require('@/assets/img/gallery/army1.png')" alt="" />
          <div id="f-animate-4-mask" class="f-animate-1-mask"></div>
        </figure>
        <div class="layout-item">
          <h2 id="t-animate5" class="title title-gradient mt-1 mb-1">
            軍武級抗菌技術
          </h2>
          <p id="p-animate5" class="detail">
            採用雙效抗菌技術能提供環境最佳的抗菌解決方案
            。以簡單噴灑可在公共場所空間、傢俱、布料、衣物、車廂、廚房
            甚至難以觸及的空間（如天花板）等即可於物體表面覆蓋一層防護膜，
            可提供長效性的抗菌保護及抑制黴菌滋生。
          </p>
        </div>
      </div>
    </section>
    <section id="trigger5" class="theme theme-dark page-five">
      <div class="layout layout-divide">
        <figure id="f-animate-5">
          <img :src="require('@/assets/img/gallery/army2.png')" alt="" />
        </figure>
      </div>
    </section>
    <!-- 第7頁 -->
    <section class="theme theme-dark machine">
      <div id="trigger7" class="layout">
        <h2 id="t-animate7" class="title title-gradient mb-1 text-center">
          空氣防疫新境界
        </h2>
        <p id="p-animate7" class="detail">
          噴流式分子霧化機外觀設計已榮獲德國紅點設計大獎 Red Dot Award 採用Micro
          Ultraviolet
          微紫外光加成技術結合獨家設計之邏輯運算晶片，能將充飽能量的帶電光合鈦粒子，以18萬次/秒以上振動速度噴流於空氣中,進而達成空氣防疫的最佳效果。
        </p>
      </div>
      <figure class="machine-img">
        <video
          muted
          autoplay
          loop
          autobuffer
          playsinline
          :src="require('@/assets/img/video/machine.mp4')"
        ></video>
      </figure>
    </section>
    <!-- 第8頁 -->
    <section id="trigger8" ref="pageEight" class="theme theme-light page-eight">
      <div
        id="t-animate8"
        class="title title-gradient-w"
        v-html="
          clientMode === 'pc'
            ? '經實驗證實能有效抵禦各種細菌病毒'
            : '經實驗證實能有效抵禦各種\n細菌病毒'
        "
      ></div>
      <swiper
        ref="mySwiper"
        :options="swiperOptions"
        :pagination="{ type: 'fraction' }"
      >
        <swiper-slide
          v-for="(item, index) in 16"
          :key="index"
          @click.native="scaleLightBox(index + 1)"
        >
          <div class="report">
            <img :src="require(`@/assets/img/report/${index + 1}.png`)" />
          </div>
        </swiper-slide>
        <div class="swiper-pagination pagination" slot="pagination"></div>
      </swiper>
      <a-modal
        class="light-box"
        :getContainer="() => $refs.pageEight"
        centered
        :footer="null"
        v-model="showLightBox"
      >
        <img :src="$requireSafe(`report/${lightBoxImg}.png`)" />
      </a-modal>
    </section>
    <!-- 第9頁 -->
    <section id="trigger9" class="theme theme-light page-nine">
      <div class="wrapper">
        <div id="t-animate9" class="title title-gradient-w">
          來自森林大地的自然芬芳
        </div>
        <div class="content">
          <div id="p-animate9-1" class="desc detail">
            光合鈦始終堅持挑選自然農法栽種的野生花草植物做為基礎原料，是源自於對大地回饋的使命感。
            光合鈦主成份為天然植物萃取(99%)，植物特別引進「日本自然農法種植技術」長期與在地小農契作生產，自然農法不使用化學肥料與農藥，
            以順應大自然的方式，找回大自然與土地的平衡，更是維護消費者健康福祉及善待環境的高品質要求，
            每一批生產皆經過嚴格挑選及檢測，讓產品能達到真正對身體無慮的最高標準。
          </div>
          <div id="p-animate9-2" class="desc detail">
            為萃取最天然之植物香味成份，
            植物經高溫高壓的鍋爐蒸餾淬鍊得到的萃取液再經過核子級離子交換樹脂設備過濾 去除重金屬等金屬有害離子，
            讓產品可以達到真正無汙染的食品等級。
          </div>
          <div id="p-animate9-3" class="desc detail">
            經研究發現植物中的單寧酸，可雙重抑制新型冠狀病毒的主要蛋白酶和跨膜絲胺酸蛋白酶2，
            對於新冠肺炎的治療具有高度前瞻性。
          </div>
        </div>
      </div>
    </section>
    <!-- 第10頁 -->
    <section class="theme theme-center theme-light">
      <div id="trigger10" class="layout layout-divide col-reverse page-ten">
        <figure
          id="f-animate-10"
          class="layout-item gallery layout-item-gap-r f-animate-10"
        >
          <img :src="require('@/assets/img/gallery/product.png')" />
          <div id="f-animate-10-mask" class="f-animate-10-mask"></div>
        </figure>
        <div class="layout-item typography">
          <h2
            id="t-animate10"
            class="title title-gradient mb-1"
            v-html="
              clientMode !== 'mobile'
                ? '來自台灣國家實驗室的驚異科技'
                : '來自台灣國家實驗室的\n驚異科技'
            "
          ></h2>
          <p id="p-animate10-1" class="desc detail mb-1">
            你是否也曾感受過類似的場景？身處在疫情時代，人人自危，
            深怕下一個確診的即是自己，焦慮和恐懼等各種負面情緒，
            像一道洶洶巨浪向世紀襲來。
          </p>
          <p id="p-animate10-2" class="desc detail">
            FORDAYS 獨家結合國家級實驗室針對國際疫情產研合作一項專利產品；
            光合鈦在地森耕利用獨家天然草本植物配方，結合先進半導體微生物分解技術，
            能迅速分解空氣及空間中有害身體的病菌及汙染物，產品並通過國際P4等級實驗室認證合格,
            能迅速及有效的抑制新型 Delta。
          </p>
        </div>
      </div>
    </section>
    <!-- 第11頁 -->
    <section class="theme theme-center theme-light">
      <div id="trigger11" class="layout layout-divide page-eleven">
        <div class="layout-item typography">
          <h2
            id="t-animate11"
            class="title title-gradient mb-1"
            v-html="'SNQ國家品質標章\n國家級防疫認證'"
          ></h2>
          <p id="p-animate11" class="detail">
            SNQ國家品質標章的防疫類產品認證，是目前全國唯一可以對防疫類產品去作認證及把關的機制。
            SNQ（Symbol of National
            Quality）國家品質標章是由國內防疫權威學者所組成的專家評委，從眾多評選商品中就成份、安全、及效能作綜合評比；
            並挑選出能代表國家榮譽之防疫商品，SNQ國家品質標章不但可讓消費者知道什麼樣的產品是過度包裝華而不實，什麼樣的產品是認真研究真正具有效用及安全性的產品。
            由於SNQ嚴苛的嚴選標準，不容一絲馬虎的精神，也不容一絲不具科學性的驗證，這正也是將光合鈦推升於國際舞台的堅強背書。
          </p>
        </div>
        <figure
          id="f-animate-11"
          class="layout-item gallery layout-item-gap-l f-animate-11"
        >
          <img :src="require('@/assets/img/gallery/snq.png')" />
          <div id="f-animate-11-mask" class="f-animate-11-mask"></div>
        </figure>
      </div>
    </section>
    <!-- 第12頁 -->
    <section class="theme theme-center theme-light">
      <div id="trigger12" class="layout layout-divide col-reverse page-twelve">
        <figure
          id="f-animate-12"
          class="layout-item gallery layout-item-gap-r f-animate-12"
        >
          <img :src="require('@/assets/img/gallery/can.png')" />
          <div id="f-animate-12-mask" class="f-animate-12-mask"></div>
        </figure>
        <div class="layout-item typography">
          <h2
            id="t-animate12"
            class="title title-gradient mb-1"
            v-html="
              clientMode !== 'mobile'
                ? 'FORDAYS 跨世代機能性抗菌香氛'
                : 'FORDAYS\n跨世代機能性抗菌香氛'
            "
          ></h2>
          <p id="p-animate12" class="detail">
            閉上眼睛，我們將帶您到達夢想的彼岸 美好的芬芳體驗，
            就如同選對優質的事業 而自古就存在的芳香療法， 更有著奇妙的療癒訊息
            一股股波動的自然能量，提振我們疲乏的精神 安撫緊張焦慮的情緒
            轉化我們被環境人事消磨殆盡的耐心與美貌 這就是 FORDAYS — 在地森耕
          </p>
        </div>
      </div>
    </section>
    <!-- 第13頁 -->
    <section class="theme theme-center theme-light">
      <div id="trigger13" class="layout page-thirteen">
        <figure id="f-animate-13" class="layout-item gallery f-animate-13 page-thirteen-figure">
          <img :src="require('@/assets/img/gallery/global.png')" />
          <div id="f-animate-13-mask" class="f-animate-13-mask"></div>
        </figure>
        <div class="layout-item typography page-thirteen-desc">
          <h2
            id="t-animate13"
            class="title title-gradient text-center mb-1"
            v-html="'金商榮耀 雙獎肯定'"
          ></h2>
          <p id="p-animate13-1" class="desc detail mb-1">
            日商富地滋台灣分公司，在幸田望希社長的領導下，分別獲得了110年度金商獎「優良外商」及「優良商人」
            雙獎項。
            金商獎的選拔過程會同產、官、學三方嚴謹審核，最終由經濟部選拔核定、頒發證書，並接受總統與行政院長晉見表揚。
            獲獎者即代表企業是優良品牌，並給予企業形象最高肯定與榮譽的獎項。
            歷年入選者全都是國際馳名的一流企業，舉凡日商三菱、中華賓士、可口可樂、輝瑞大藥廠等跨國企業，遴選遍及電子科技、金融、貿易等業界。
            而日商富地滋為直銷業界歷年來唯一的日商企業公司，在品牌力與商業力上與國際一流跨國企業並駕齊驅，實力有目共睹。
          </p>
          <p id="p-animate13-2" class="desc detail mb-1">
            日商富地滋18年來用心守護台灣人民健康，並提供一份優質的事業機會。公司除持續拓展營運據點外，營收表現並逐年攀升。
            最主要的原因除了產品會說話之外，更多是來自「夥伴」的共創，才能成就今日豐碩的成果。
          </p>
          <p id="p-animate13-3" class="desc detail">
            FORDAYS寓含了『為了健康快樂的每一天』的意義，也包含許多人希望『邁向豐富的人生』的期許。
            我們以一期一會的心，珍惜每一次的相遇，感謝每一則動人的故事。富地滋在時代的急遽變化中成功展現出更具有人性的一面，並以促進大眾的健康和幸福為目標，與大家攜手未來。
          </p>
        </div>
      </div>
    </section>
    <!-- 第14頁 -->
    <section id="trigger14" class="theme theme-light page-fourteen">
      <div class="left">
        <div id="t-animate14" class="title title-gradient-w">
          台灣出發 用心視界
        </div>
        <div id="p-animate14-1" class="desc detail">
          光合鈦從台灣出發 用台灣心 台灣情 將台灣引以爲傲的科技實力技術攜手世界共同防疫，就讓我們手牽手心連心ㄧ同戰勝 COVID -19。
        </div>
        <div id="p-animate14-2" class="desc detail">
          光合鈦在地森耕已申請台灣發明專利字第110117184號 「抗微生物組合物及其製造方法」、台灣新型專利字第M618918號「抗微生物薄膜技術」、中國專利字第202210217037.4號「抗微生物組合物及其製造方法」、越南專利字第1-2022-02098號「抗微生物组合物及其制造方法」、日本專利字第 特願2022-061902號「抗微生物組成物及びその製造方法」，將以全心的角度為全人類貢獻己力 這也是 FORDAYS 不變的精神。
        </div>
      </div>
      <div class="right"></div>
    </section>
    <!-- 第15頁 -->
    <section id="trigger15" class="theme theme-light page-fifteen">
      <div id="t-animate15" class="title title-gradient-w">
        <span class="title-text">台灣真價值&nbsp;&nbsp;</span>
        <span class="title-text">富地滋與世界攜手防疫</span>
      </div>
      <div class="video">
        <iframe
          :src="`https://www.youtube.com/embed/${videoEmbedLink}`"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
      </div>
      <div class="video-list">
        <div
          class="video-list-card"
          v-for="(vdo, vdoIndex) in videoList"
          :key="`vdo${vdoIndex}`"
          @click="changeVideo(vdo.url)"
        >
        <div class="vdo-img">
          <img class="preview" :src="$requireSafe(`yt/${vdo.img}.png`)" />
          <div class="play">
            <img :src="$requireSafe(`icon/play.svg`)" />
          </div>
        </div>
          <div class="vdo-title">
            {{ vdo.title }}
          </div>
        </div>
      </div>
    </section>
    <div id="fader" class="fader"></div>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper';
import title from '@/assets/js/mixins/title';
import paragraph from '@/assets/js/mixins/paragraph';
import figure from '@/assets/js/mixins/figure';

export default {
  name: 'App',
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      fullVh: 0,
      swiperOptions: {
        effect: 'coverflow',
        // autoplay: true,
        grabCursor: true,
        centeredSlides: true,
        loop: true,
        slidesPerView: 'auto',
        coverflowEffect: {
          rotate: 0,
          stretch: -12,
          depth: 250,
          modifier: 2.5,
          slideShadows: true,
        },
        pagination: {
          el: '.swiper-pagination',
          type: 'fraction',
          clickable: true,
        },
      },
      showLightBox: false,
      lightBoxImg: undefined,
      clientMode: 'pc',
      fadeVideo: false,
      videoEmbedLink: '9ZslTP7iF9U',
      videoList: [
        {
          img: 1,
          url: '9ZslTP7iF9U',
          title: '光合鈦能有效擊落 空氣中的有害微生物',
        },
        { img: 2, url: '-zd3WLgHZmQ', title: '台灣防疫產品 國際化的第一步' },
        { img: 3, url: 'aaBbvdpMcv8', title: '防疫產品需要品質認證嗎？' },
        { img: 4, url: 'nzxCFBoGvmk', title: '揭開台灣P4實驗室神秘面紗' },
      ],
    };
  },
  mixins: [title, paragraph, figure],
  computed: {
    swiper() {
      return this.$refs.mySwiper.$swiper;
    },
  },
  mounted() {
    const vm = this;

    if (window.innerWidth < 768) {
      vm.clientMode = 'mobile';
    } else if (window.innerWidth <= 992) {
      vm.clientMode = 'tablet';
    } else {
      vm.clientMode = 'pc';
    }

    vm.handleFullHeight();
    vm.handleTitle();
    vm.handleParagraph();
    vm.handleFigure();

    window.onresize = () => {
      if (window.innerWidth <= 768) {
        vm.clientMode = 'mobile';
      } else if (window.innerWidth <= 992) {
        vm.clientMode = 'tablet';
      } else {
        vm.clientMode = 'pc';
      }

      vm.$scrollmagic.updateScene();
    };
  },
  methods: {
    handleFullHeight() {
      // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
      const vm = this;
      const vh = window.innerHeight * 0.01;
      // Then we set the value in the --vh custom property to the root of the document
      document.querySelector('.theme').style.setProperty('--vh', `${vh}px`);
      vm.fullVh = vh * 100;
    },
    scaleLightBox(url) {
      const vm = this;
      vm.showLightBox = true;
      vm.lightBoxImg = url;
    },
    hideVideo() {
      const vm = this;
      vm.fadeVideo = true;
    },
    changeVideo(url) {
      const vm = this;
      vm.videoEmbedLink = url;
    },
  },
};
</script>

<style lang="scss" scoped>
$header-height: 80px;
$header-height-pc: 60px;
$header-height-tablet: 60px;
$header-height-mobile: 32px;

.header {
  position: absolute;
  top: 0;
  left: 60px;
  z-index: 200;
  height: $header-height;

  @include mediaquery_pc {
    height: $header-height-pc;
  }

  @include mediaquery_minipad {
    @include abs-center-x;

    height: $header-height-pc;
  }

  @include mediaquery_phone {
    @include abs-center-x;

    height: $header-height-mobile;
  }

  .logo {
    height: 100%;
    cursor: pointer;
  }

  .logo img {
    max-height: 100%;
    transform: translateZ(0);
  }
}

.intro {
  // page 1
  @include flex-center;

  position: relative;
  flex-direction: column;

  &-content {
    .title {
      transition: 1.5s;
      transform: scale(0.5);
      opacity: 0;
    }

    .detail {
      transition: 1.5s;
      transition-delay: 0.5s;
      transform: scale(0.5);
      opacity: 0;
    }

    width: 100%;
    max-width: 1364px;
    margin: 0 auto;
    text-align: center;
  }

  @include mediaquery_minipad {
    padding: $header-height-tablet + 80px 0 80px;
  }

  @include mediaquery_phone {
    padding: $header-height-mobile + 80px 0 60px;
  }
}

.diligent {
  // page 3
  padding: 360px 0;

  @include mediaquery_phone {
    padding: 60px 0;
  }

  @include mediaquery_pad {
    padding: 80px 0 0;
  }
}

.succeeded {
  // 我們成功了 page 4
  @include flex-center;

  position: relative;
  padding: 140px 0;

  &-figure {
    @include abs-center;
    @include flex-center;

    z-index: 0;
    width: 100%;
    height: 100%;
  }

  &-figure > img {
    min-height: 294px;
    object-fit: cover;
    transform: translateZ(0);
  }

  &-typography {
    z-index: 1;
  }
}

.army {
  padding: 80px 0;

  @include mediaquery_phone {
    padding: 20px 0;
  }

  @include mediaquery_pad {
    padding: 20px 0;
  }
}

.machine {
  padding: 160px 0 0;

  .machine-img {
    display: flex;
    justify-content: center;
    padding-bottom: 40px;

    video {
      max-width: 100%;
    }

    img {
      width: 100%;
      height: 100vw;
    }
  }

  @include mediaquery_minipad {
    padding: 80px 0 0;
  }

  @include mediaquery_phone {
    padding: 40px 0 0;
  }
}

.page-eight {
  padding: 160px 140px 80px;

  /deep/ .ant-modal {
    width: 600px !important;
  }

  @include mediaquery_minipad {
    padding: 60px 100px;

    /deep/ .ant-modal {
      width: 500px !important;
    }
  }

  @include mediaquery_phone {
    padding: 60px 20px;

    /deep/ .ant-modal {
      width: 360px !important;
    }
  }

  .title {
    text-align: center;
  }

  /deep/ .swiper-container {
    margin: 55px 20px;
    background-color: #fff;

    .swiper-slide {
      @include flex-center;

      width: 35%;
      height: auto;
      background-position: center;
      background-size: cover;
      filter: blur(1px);

      .report {
        width: 90%;
        height: auto;

        img {
          width: 100%;
          height: auto;
          transform: translateZ(0);
        }
      }

      &-active {
        filter: blur(0);
      }
    }

    @include mediaquery_pad {
      margin: 35px 10px;
    }

    @include mediaquery_phone {
      margin: 35px 0;
    }
  }

  /deep/ .swiper-wrapper {
    padding-bottom: 60px;

    @include mediaquery_pc {
      padding-bottom: 48px;
    }

    @include mediaquery_minipad {
      padding-bottom: 48px;
    }

    @include mediaquery_phone {
      padding-bottom: 40px;
    }
  }

  /deep/ .swiper-pagination-bullet-active {
    background: #11b2a9;
  }

  /deep/ .swiper-pagination-bullet {
    width: 18px;
    height: 18px;

    @include mediaquery_pc {
      width: 14px;
      height: 14px;
    }

    @include mediaquery_minipad {
      width: 10px;
      height: 10px;
    }

    @include mediaquery_phone {
      width: 8px;
      height: 8px;
    }
  }

  /deep/
  .swiper-container-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet {
    margin: 0 8px;

    @include mediaquery_pc {
      margin: 0 6px;
    }

    @include mediaquery_minipad {
      margin: 0 4px;
    }
  }
}

.page-fifteen {
  padding: 160px 140px 80px;

  @include mediaquery_pc {
    padding: 80px 140px * 0.7;
  }

  @include mediaquery_minipad {
    padding: 60px 140px * 0.5;
  }

  @include mediaquery_phone {
    padding: 60px 36px;
  }

  .video-list {
    display: flex;
    flex-wrap: wrap;
    height: 100%;
    margin: 40px -20px;

    &-card {
      display: flex;
      flex: 0 0 25%;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 100%;
      padding: 0 20px;
      text-align: center;

      @include mediaquery_pad {
        flex: 0 0 50%;
        margin: 10px 0;
      }

      &:hover {
        cursor: pointer;
        opacity: 0.8;

        .vdo-img {
          .play {
            img {
              filter: opacity(1);
            }
          }
        }
      }

      &:active {
        opacity: 0.6;
      }

      .vdo-img {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;

        .preview {
          filter: brightness(0.5);
        }

        .play {
          position: absolute;
          width: 15%;

          img {
            filter: opacity(0.6);
          }
        }
      }

      .vdo-title {
        margin-top: 10px;
        line-height: 25px;
      }
    }
  }
}
</style>
