// 標題動畫
export default {
  methods: {
    handleTitle() {
      const vm = this;

      vm.handleTitlePageTwo();
      vm.handleTitlePageThree();
      vm.handleTitlePageFour();
      vm.handleTitlePageFive();
      vm.handleTitlePageSeven();
      vm.handleTitlePageEight();
      vm.handleTitlePageNine();
      vm.handleTitlePageTen();
      vm.handleTitlePageEleven();
      vm.handleTitlePageTwelve();
      vm.handleTitlePageThirteen();
      vm.handleTitlePageFourteen();
      vm.handleTitlePageFifteen();
    },
    handleTitlePageTwo() {
      const vm = this;

      const scene = vm.$scrollmagic.scene({
        triggerElement: '#trigger2',
        triggerHook: vm.clientMode === 'mobile' ? 0.8 : 0.5,
        duration: 600,
      })
        .setTween('#t-animate2', {
          css: {
            backgroundImage: 'linear-gradient(135deg, rgba(255, 255, 255, 1) 100%, rgba(240, 240, 240, 0) 200%)',
          },
        });
        // .addIndicators({ name: '2 (duration: 300)' });

      vm.$scrollmagic.addScene(scene);
    },
    handleTitlePageThree() {
      const vm = this;

      const scene = vm.$scrollmagic.scene({
        triggerElement: '#trigger3',
        triggerHook: vm.clientMode === 'mobile' ? 0.8 : 0.5,
        duration: 600,
      })
        .setTween('#t-animate3', {
          css: {
            backgroundImage: 'linear-gradient(135deg, rgba(255, 255, 255, 1) 100%, rgba(240, 240, 240, 0) 200%)',
          },
        });

      vm.$scrollmagic.addScene(scene);
    },
    handleTitlePageFour() {
      const vm = this;

      const scene1 = vm.$scrollmagic.scene({
        triggerElement: '#trigger4-1',
        triggerHook: 0.5,
        duration: 300,
      })
        .setTween('#t-animate4-1', {
          css: {
            transform: 'translateY(0px)',
            opacity: 1,
          },
        });

      vm.$scrollmagic.addScene(scene1);

      const scene2 = vm.$scrollmagic.scene({
        triggerElement: '#t-animate4-1',
        triggerHook: 0.5,
        duration: 300,
      })
        .setTween('#t-animate4-2', {
          css: {
            transform: 'translateY(0px)',
            opacity: 1,
          },
        });

      vm.$scrollmagic.addScene(scene2);

      const scene3 = vm.$scrollmagic.scene({
        triggerElement: '#t-animate4-2',
        triggerHook: 0.5,
        duration: 200,
      })
        .setTween('#t-animate4-3', {
          css: {
            transform: 'translateY(0px)',
            opacity: 1,
          },
        });

      vm.$scrollmagic.addScene(scene3);
    },
    handleTitlePageFive() {
      const vm = this;

      const scene = vm.$scrollmagic.scene({
        triggerElement: '#triggerr4-2',
        triggerHook: vm.clientMode === 'mobile' ? 0.6 : 0.5,
        duration: 600,
        offset: vm.clientMode === 'tablet' ? 500 : 0,
      })
        .setTween('#t-animate5', {
          css: {
            backgroundImage: 'linear-gradient(135deg, rgba(255, 255, 255, 1) 100%, rgba(240, 240, 240, 0) 200%)',
          },
        });

      vm.$scrollmagic.addScene(scene);
    },
    handleTitlePageSeven() {
      const vm = this;

      const scene = vm.$scrollmagic.scene({
        triggerElement: '#trigger7',
        triggerHook: vm.clientMode === 'mobile' ? 0.8 : 0.6,
        duration: 800,
      })
        .setTween('#t-animate7', {
          css: {
            backgroundImage: 'linear-gradient(135deg, rgba(255, 255, 255, 1) 100%, rgba(240, 240, 240, 0) 200%)',
          },
        });

      vm.$scrollmagic.addScene(scene);
    },
    handleTitlePageEight() {
      const vm = this;

      const scene = vm.$scrollmagic.scene({
        triggerElement: '#trigger8',
        triggerHook: 0.7,
        duration: 600,
      })
        .setTween('#t-animate8', {
          css: {
            backgroundImage: 'linear-gradient(135deg, rgba(0, 0, 0, 1) 100%, rgba(255, 255, 255, 0) 200%)',
          },
        });

      vm.$scrollmagic.addScene(scene);
    },
    handleTitlePageNine() {
      const vm = this;

      const scene = vm.$scrollmagic.scene({
        triggerElement: '#trigger9',
        triggerHook: vm.clientMode === 'mobile' ? 1 : 0.8,
        duration: 600,
      })
        .setTween('#t-animate9', {
          css: {
            backgroundImage: 'linear-gradient(135deg, rgba(0, 0, 0, 1) 100%, rgba(255, 255, 255, 0) 200%)',
          },
        });

      vm.$scrollmagic.addScene(scene);
    },
    handleTitlePageTen() {
      const vm = this;

      const scene = vm.$scrollmagic.scene({
        triggerElement: '#trigger10',
        triggerHook: vm.clientMode === 'mobile' ? 1 : 0.5,
        duration: 600,
      })
        .setTween('#t-animate10', {
          css: {
            backgroundImage: 'linear-gradient(135deg, rgba(0, 0, 0, 1) 100%, rgba(255, 255, 255, 0) 200%)',
          },
        });
        // .addIndicators({ name: '2 (duration: 300)' });

      vm.$scrollmagic.addScene(scene);
    },
    handleTitlePageEleven() {
      const vm = this;

      const scene = vm.$scrollmagic.scene({
        triggerElement: '#trigger11',
        triggerHook: 1,
        duration: 600,
      })
        .setTween('#t-animate11', {
          css: {
            backgroundImage: 'linear-gradient(135deg, rgba(0, 0, 0, 1) 100%, rgba(255, 255, 255, 0) 200%)',
          },
        });
        // .addIndicators({ name: '2 (duration: 300)' });

      vm.$scrollmagic.addScene(scene);
    },
    handleTitlePageTwelve() {
      const vm = this;

      const scene = vm.$scrollmagic.scene({
        triggerElement: '#trigger12',
        triggerHook: vm.clientMode === 'mobile' ? 1 : 1,
        duration: 600,
      })
        .setTween('#t-animate12', {
          css: {
            backgroundImage: 'linear-gradient(135deg, rgba(0, 0, 0, 1) 100%, rgba(255, 255, 255, 0) 200%)',
          },
        });
        // .addIndicators({ name: '2 (duration: 300)' });

      vm.$scrollmagic.addScene(scene);
    },
    handleTitlePageThirteen() {
      const vm = this;

      const scene = vm.$scrollmagic.scene({
        triggerElement: '#t-animate13',
        triggerHook: 1,
        duration: 600,
      })
        .setTween('#t-animate13', {
          css: {
            backgroundImage: 'linear-gradient(135deg, rgba(0, 0, 0, 1) 100%, rgba(255, 255, 255, 0) 200%)',
          },
        });
        // .addIndicators({ name: '2 (duration: 300)' });

      vm.$scrollmagic.addScene(scene);
    },
    handleTitlePageFourteen() {
      const vm = this;

      const scene = vm.$scrollmagic.scene({
        triggerElement: '#trigger14',
        triggerHook: vm.clientMode !== 'pc' ? 0.8 : 0.5,
        duration: 600,
      })
        .setTween('#t-animate14', {
          css: {
            backgroundImage: 'linear-gradient(135deg, rgba(0, 0, 0, 1) 100%, rgba(255, 255, 255, 0) 200%)',
          },
        });

      vm.$scrollmagic.addScene(scene);
    },
    handleTitlePageFifteen() {
      const vm = this;

      const scene = vm.$scrollmagic.scene({
        triggerElement: '#trigger15',
        triggerHook: vm.clientMode !== 'pc' ? 0.8 : 0.5,
        duration: 600,
        offset: vm.clientMode !== 'pc' ? -100 : 0,
      })
        .setTween('#t-animate15', {
          css: {
            backgroundImage: 'linear-gradient(135deg, rgba(0, 0, 0, 1) 100%, rgba(255, 255, 255, 0) 200%)',
          },
        });
        // .addIndicators({ name: '2 (duration: 300)' });

      vm.$scrollmagic.addScene(scene);
    },
  },
};
