// 圖片動畫
export default {
  methods: {
    handleFigurePageTwo() {
      const vm = this;
      const endPoint = 200;
      const scene = vm.$scrollmagic.scene({
        triggerElement: '#trigger2',
        triggerHook: 0.5,
        duration: endPoint,
      })
        .setTween('#f-animate-1', {
          css: {
            transform: 'matrix(1, 0, 0, 1, 0, 0)',
          },
        });

      vm.$scrollmagic.addScene(scene);

      const scene2 = vm.$scrollmagic.scene({
        triggerElement: '#trigger2',
        triggerHook: 0.5,
        duration: '100%',
      })
        .setTween('#f-animate-1-mask', {
          css: {
            backgroundImage: 'radial-gradient(at center 90%, rgba(16, 16, 17, 0) 0%, rgba(16, 16, 17, 0) 100%)',
          },
        });

      vm.$scrollmagic.addScene(scene2);
    },
    handleFigurePageThree() {
      const vm = this;
      const endPoint = 200;
      const scene = vm.$scrollmagic.scene({
        triggerElement: '#trigger3',
        triggerHook: 0.7,
        duration: endPoint,
      })
        .setTween('#f-animate-2', {
          css: {
            transform: 'matrix(1, 0, 0, 1, 0, 0)',
          },
        });

      vm.$scrollmagic.addScene(scene);

      const scene2 = vm.$scrollmagic.scene({
        triggerElement: '#trigger3',
        triggerHook: 0.5,
        duration: '100%',
      })
        .setTween('#f-animate-2-mask', {
          css: {
            backgroundImage: 'radial-gradient(at center 90%, rgba(16, 16, 17, 0) 0%, rgba(16, 16, 17, 0) 100%)',
          },
        });

      vm.$scrollmagic.addScene(scene2);
    },
    handleFigurePageFive() {
      const vm = this;
      const endPoint = 200;
      const scene = vm.$scrollmagic.scene({
        triggerElement: '#triggerr4-2',
        triggerHook: 0.5,
        duration: endPoint,
      })
        .setTween('#f-animate-4', {
          css: {
            transform: 'matrix(1, 0, 0, 1, 0, 0)',
          },
        });

      vm.$scrollmagic.addScene(scene);

      const scene2 = vm.$scrollmagic.scene({
        triggerElement: '#triggerr4-2',
        triggerHook: 0.5,
        duration: '100%',
      })
        .setTween('#f-animate-4-mask', {
          css: {
            backgroundImage: 'radial-gradient(at center 90%, rgba(16, 16, 17, 0) 0%, rgba(16, 16, 17, 0) 100%)',
          },
        });

      vm.$scrollmagic.addScene(scene2);
    },
    handleFigurePageSix() {
      const vm = this;
      const endPoint = 200;
      const scene = vm.$scrollmagic.scene({
        triggerElement: '#trigger5',
        triggerHook: 0.5,
        duration: endPoint,
      })
        .setTween('#f-animate-5', {
          css: {
            transform: 'matrix(1, 0, 0, 1, 0, 0)',
          },
        });

      vm.$scrollmagic.addScene(scene);

      const scene2 = vm.$scrollmagic.scene({
        triggerElement: '#trigger5',
        triggerHook: 0.5,
        duration: '100%',
      })
        .setTween('#f-animate-5-mask', {
          css: {
            backgroundImage: 'radial-gradient(at center 90%, rgba(16, 16, 17, 0) 0%, rgba(16, 16, 17, 0) 100%)',
          },
        });

      vm.$scrollmagic.addScene(scene2);
    },
    handleFigurePageTen() {
      const vm = this;
      const endPoint = 200;
      const scene = vm.$scrollmagic.scene({
        triggerElement: '#trigger10',
        triggerHook: vm.clientMode === 'mobile' ? 0.1 : 0.7,
        duration: endPoint,
      })
        .setTween('#f-animate-10', {
          css: {
            transform: 'matrix(1, 0, 0, 1, 0, 0)',
          },
        });

      vm.$scrollmagic.addScene(scene);

      const scene2 = vm.$scrollmagic.scene({
        triggerElement: '#trigger10',
        triggerHook: 0.5,
        duration: '100%',
      })
        .setTween('#f-animate-10-mask', {
          css: {
            backgroundImage: 'radial-gradient(at center 90%, rgba(16, 16, 17, 0) 0%, rgba(16, 16, 17, 0) 100%)',
          },
        });

      vm.$scrollmagic.addScene(scene2);
    },
    handleFigurePageEleven() {
      const vm = this;
      const endPoint = 200;
      const scene = vm.$scrollmagic.scene({
        triggerElement: '#f-animate-11',
        triggerHook: 0.9,
        duration: endPoint,
      })
        .setTween('#f-animate-11', {
          css: {
            transform: 'matrix(1, 0, 0, 1, 0, 0)',
          },
        });

      vm.$scrollmagic.addScene(scene);

      const scene2 = vm.$scrollmagic.scene({
        triggerElement: '#trigger11',
        triggerHook: 0.7,
        duration: '100%',
      })
        .setTween('#f-animate-11-mask', {
          css: {
            backgroundImage: 'radial-gradient(at center 90%, rgba(16, 16, 17, 0) 0%, rgba(16, 16, 17, 0) 100%)',
          },
        });

      vm.$scrollmagic.addScene(scene2);
    },
    handleFigurePageTwelve() {
      const vm = this;
      const endPoint = 200;
      const scene = vm.$scrollmagic.scene({
        triggerElement: '#f-animate-12',
        triggerHook: 0.9,
        duration: endPoint,
      })
        .setTween('#f-animate-12', {
          css: {
            transform: 'matrix(1, 0, 0, 1, 0, 0)',
          },
        });

      vm.$scrollmagic.addScene(scene);

      const scene2 = vm.$scrollmagic.scene({
        triggerElement: '#trigger12',
        triggerHook: 0.5,
        duration: '100%',
      })
        .setTween('#f-animate-12-mask', {
          css: {
            backgroundImage: 'radial-gradient(at center 90%, rgba(16, 16, 17, 0) 0%, rgba(16, 16, 17, 0) 100%)',
          },
        });

      vm.$scrollmagic.addScene(scene2);
    },
    handleFigurePageThirteen() {
      const vm = this;
      const endPoint = 200;
      const scene = vm.$scrollmagic.scene({
        triggerElement: '#trigger13',
        triggerHook: 0.7,
        duration: endPoint,
      })
        .setTween('#f-animate-13', {
          css: {
            transform: 'matrix(1, 0, 0, 1, 0, 0)',
          },
        });

      vm.$scrollmagic.addScene(scene);
    },
    handleFigure() {
      const vm = this;
      vm.handleFigurePageTwo();
      vm.handleFigurePageThree();
      vm.handleFigurePageFive();
      // vm.handleFigurePageSix();
      vm.handleFigurePageTen();
      vm.handleFigurePageEleven();
      vm.handleFigurePageTwelve();
      vm.handleFigurePageThirteen();
    },
  },
};
