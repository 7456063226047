import '@/assets/scss/main.scss';
import Vue from 'vue';
import VueAwesomeSwiper from 'vue-awesome-swiper';
import { Modal } from 'ant-design-vue';
import VueScrollmagic from 'vue-scrollmagic';
import App from './App.vue';
import 'swiper/css/swiper.css';

Vue.use(VueScrollmagic, {
  vertical: true,
  globalSceneOptions: {},
  loglevel: 2,
  refreshInterval: 100,
});
Vue.use(VueAwesomeSwiper);
Vue.use(Modal);
Vue.config.productionTip = false;
Vue.prototype.$requireSafe = (path) => {
  try {
    /* eslint-disable */
    return require(`@/assets/img/${path}`);
    /* eslint-disable */
  } catch (err) {
    return undefined;
  }
};

new Vue({
  render: (h) => h(App),
}).$mount('#app');
